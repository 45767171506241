.user-conversation-group  {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    justify-items: center;
    width: 100%;
    bottom: 10%;
}

.user-conversation-group > div {
    max-width: 700px;
    min-width: 350px;
    display: grid;
    justify-items: center;
    position: relative;
    width: 100%;
    height: 140px;
    animation: OpacityChatAnimation .8s ease-in-out;
}

.user-conversation-group > div .user-option:first-child {
    bottom: 0;
    left: 0;
}

.user-conversation-group > div .user-option:last-child {
    bottom: 0;
    right: 0;
}
.user-conversation-group .user-option {
    position: absolute;
    margin-bottom: 0 !important;
}

.user-conversation-group .user-option > span {
    color: white;
    font-size: 13px;
    line-height: 13px;
    font-weight: normal;
}

.chat-bubble {
    animation: OpacityAnimation 1.8s ease-in-out;
    background-color: #fefefe12;
    padding: 16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display: inline-block;
    margin-top: 5px;
}

.typing {
    align-items: center;
    display: flex;
    height: 17px;
}

.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #60dfff4f;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}

.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}

.typing .dot:last-child {
    margin-right: 0;
}

.animation-charge {
    animation: OpacityChatAnimation .8s ease-in-out;
}

.animation-charge-short {
    animation: OpacityChatAnimation .4s ease-in-out;
}

.dropdown-content {
    opacity: 0;
    height: 0px;
    max-height: 150px;
    overflow: auto;
    border-radius: 5px;
    padding: 0;
    box-shadow: 0px 2px 10px rgba(144, 144, 144, 0.508);
    transition: padding ease-in-out 400ms, opacity ease-in-out 600ms, height ease-in-out 500ms;
}

.dropdown-content::-webkit-scrollbar {
    width: 3px;
}
  
.dropdown-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin: 5px;
}

.dropdown-content::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    padding: 10px;
    margin: 10px;
}

.dropdown-content div {
    color: black;
    background-color: #f9f9f9;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
}

.dropdown .dropdown-content {
    opacity: 100;
    height: 150px;
    padding: 5px;
}

.gradient-red {
    background-image: linear-gradient(0deg, #FF005B, transparent);
}

.gradient-blue {
    background-image: linear-gradient(0deg, #5D43FF, transparent);
}

.gradient-yellow {
    background-image: linear-gradient(0deg, #FFD300, transparent);
}

.gradient-green {
    background-image: linear-gradient(0deg, #1FFFAE, transparent);
}

.gradient-red-horizontal {
    background-image: linear-gradient(45deg, #FF005B, transparent);
}

.gradient-blue-horizontal {
    background-image: linear-gradient(45deg, #5D43FF, transparent);
}

.gradient-yellow-horizontal {
    background-image: linear-gradient(45deg, #FFD300, transparent);
}

.gradient-green-horizontal {
    background-image: linear-gradient(45deg, #1FFFAE, transparent);
}

.tooltip:hover {
    .tooltip-icon {
        display: none !important;
    }
}

.tooltip .tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.card-verb {
    background-image: url(../images/cards/bg-interest.svg);
    background-position: center;
    background-size: contain;
    background-origin: content-box;
    background-repeat: no-repeat;
    .card-description {
        display: none;
        height: 0;
        opacity: 0;
    }
}

.card-verb:hover, .card-select {
    background-image: url(../images/cards/bg-interest-large.svg);
    .card-description {
        display: initial;
        height: fit-content;
        opacity: 1;
    }
}

.card-verb-0:after {
    content:'';
    background-image: url(../images/arrows/arrow-card-verb-conqueror.svg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: -10px;
    right: -72px;
    width: 5rem;
    aspect-ratio: 3.2;
}

.card-verb-1:after {
    content:'';
    background-image: url(../images/arrows/arrow-card-verb-creator.svg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: -9px;
    left: -42px;
    width: 4rem;
    aspect-ratio: 2.45;
    z-index: 10;
}

.card-verb-2:after {
    content:'';
    background-image: url(../images/arrows/arrow-card-verb-diver.svg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: -35px;
    left: -60px;
    width: 5rem;
    aspect-ratio: 1.5;
}

.card-verb-3:after {
    content: '';
    background-image: url(../images/arrows/arrow-card-verb-weaver.svg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: -41px;
    right: -100px;
    width: 6.5rem;
    aspect-ratio: 1.6;
}


// .card-verb.card-verb-selected-0 {
//     background-image: url(../images/cards/bg-verb-selected-conqueror.svg) !important;
// }

// .card-verb.card-verb-selected-1 {
//     background-image: url(../images/cards/bg-verb-selected-creator.svg) !important;
// }

// .card-verb.card-verb-selected-2 {
//     background-image: url(../images/cards/bg-verb-selected-diver.svg) !important;
// }

// .card-verb.card-verb-selected-3 {
//     background-image: url(../images/cards/bg-verb-selected-weaver.svg) !important;
// }


.card-verb.card-verb-selected-0, .card-verb.card-verb-selected-0:hover {
    background-image: url(../images/cards/bg-verb-selected-large-conqueror.svg) !important;
}

.card-verb.card-verb-selected-1, .card-verb.card-verb-selected-1:hover {
    background-image: url(../images/cards/bg-verb-selected-large-creator.svg) !important;
}

.card-verb.card-verb-selected-2, .card-verb.card-verb-selected-2:hover {
    background-image: url(../images/cards/bg-verb-selected-large-diver.svg) !important;
}

.card-verb.card-verb-selected-3, .card-verb.card-verb-selected-3:hover {
    background-image: url(../images/cards/bg-verb-selected-large-weaver.svg) !important;
}


.line-vertical-card::after {
    content: '';
    background-image: url("../images/lines/vertical-line.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 53px;
    right: 0;
    left: 1px;
    width: 100%;
    height: 72px;
}

@media screen and (min-width: 1200px) {
    .card-verb-0:after {
        bottom: -10px;
        right: -162px;
        width: 10rem;
    }
    
    .card-verb-1:after {
        bottom: -9px;
        left: -112px;
        width: 8rem;
    }
    
    .card-verb-2:after {
        bottom: -65px;
        left: -160px;
        width: 10rem;
    }
    
    .card-verb-3:after {
        bottom: -81px;
        right: -200px;
        width: 13rem;
    }
}

@media screen and (max-width: 480px){
    .user-conversation-group {
        padding: 0 15px;
        bottom: calc((100dvw * 0.2) + 30px);
    }

    .user-conversation-group > div {
        height: 100px;
        min-width: 250px;
    }

    .line-vertical-card::after {
        left: 0;
        aspect-ratio: 4;
        height: auto;
        top: 48px;
    }

    .card-verb.card-verb-selected-0, .card-verb.card-verb-selected-0:hover {
        background-image: url(../images/cards/bg-verb-selected-large-conqueror-mobile.svg) !important;
        height: 108px !important;
        background-size: auto !important;
    }
    
    .card-verb.card-verb-selected-1, .card-verb.card-verb-selected-1:hover {
        background-image: url(../images/cards/bg-verb-selected-large-creator-mobile.svg) !important;
        height: 108px !important;
        background-size: auto !important;
    }
    
    .card-verb.card-verb-selected-2, .card-verb.card-verb-selected-2:hover {
        background-image: url(../images/cards/bg-verb-selected-large-diver-mobile.svg) !important;
        height: 108px !important;
        background-size: auto !important;
    }
    
    .card-verb.card-verb-selected-3, .card-verb.card-verb-selected-3:hover {
        background-image: url(../images/cards/bg-verb-selected-large-weaver-mobile.svg) !important;
        height: 108px !important;
        background-size: auto !important;
    }

    .card-verb {
        background-image: url(../images/cards/bg-interest-large-mobile.svg);
        height: 104px !important;
        width: 130px !important;
        .card-description {
            display: initial;
            height: fit-content;
            opacity: 1;
        }
    }

    .card-verb:hover {
        background-image: url(../images/cards/bg-interest-large-mobile.svg);
        height: 104px !important;
        width: 130px !important;
    }

    .card-verb-0:after, .card-verb-1:after, .card-verb-2:after, .card-verb-3:after {
        background-image: none !important
    }
}