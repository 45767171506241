input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #FFFFFF !important;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px #070625 inset !important
}
input:focus{
    border-color: #ffffff;
}

input[type="email"], input[type="password"], input[type="text"], input[type="number"] {
    width: 100%;
    border-radius: 50px;
    background-color: #070625;
    border: 1px solid #42324E;
    line-height: 2.5rem;
    color: white;
    height: 35px;
    font-size: 14px;
}

.right-arrow::after {
    content: '';
    width: 60px;
    height: 12px;
    background-image: url('../images/buttons/arrow-right-btn.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: calc(100% - 6px);
    top: calc(50% - 6px);
}


.left-arrow::before {
    content: '';
    width: 68px;
    height: 12px;
    background-image: url('../images/buttons/arrow-left-btn.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: calc(100% - 5px);
    top: calc(50% - 6px);
    z-index: 100;
}

article.auth-page form {
    width: 100%;
    height: 100%;
    max-width: 361px;
    padding: 2rem;
    align-self: center;
    justify-self: center;
    z-index: 100;
}

article.auth-page form > div {
    width: 100%;
    height: calc(100% - 90px);
    display: grid;
    grid-template-rows: minmax(350px, 1fr);
    align-items: center;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/slider-range.svg');
    background-size: calc(100% - 5px);
    background-position: center;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-origin: border-box;
    object-fit: fill;
}

input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb {
    background-image: url('../images/slider-thumb.svg');
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    object-fit: fill;
    width: 15px;
    height: 15px;
    padding: 0;
    border: 0;
    aspect-ratio: 1.2;
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration{
    display: none
}

@media screen and (min-width: 1200px) {
    input[type="email"], input[type="password"], input[type="text"], input[type="number"] {
        height: 47px;
    }

    article.auth-page form {
        max-width: 500px;
    }
    article.auth-page form > div {
        grid-template-rows: minmax(400px, 1fr);
    }
}

@media screen and (min-width: 1920px) {
    input[type="email"], input[type="password"], input[type="text"], input[type="number"] {
        height: 52px;
        font-size: 20px;
        border: 2px solid #42324E;
    }

    article.auth-page form {
        max-width: 565px;
    }
}

@media screen and (max-width: 480px) {

    
    article.auth-page form {
        max-width: 92%;
        display: flex;
    }

    article.auth-page form > div {
        height: calc(100% - 180px);
        margin: auto;
    }

    input[type="email"], input[type="password"], input[type="text"], input[type="number"]  {
        font-size: 12px;
        height: 34px;
    }

    .points {
        display: none;
    }
}