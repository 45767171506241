.dashboard-slider{
    .slick-list{
        overflow: inherit !important;
        padding-left: 80px;
        .slick-track .slick-slide{
            margin: 0 15px
        }
    }
    .slick-prev,
    .slick-next{
        width: 16px;
        height: 34px;
        z-index: 20;
    }
    .slick-prev{
        left: 22px;
    }
    .slick-next{
        right: 22px;
    }
}

.vertical-slider{
    .slick-list{
        overflow: inherit !important;
    }
    .slick-vertical{
        height: 100%;
    }
    .slick-prev,
    .slick-next{
        width: 34px;
        height: 16px;
        left: 0 !important;
        right: 0 !important;
        margin: auto;
        z-index: 20;
    }
    .slick-prev{
        top: -30px;
        bottom: inherit;
    }
    .slick-next{
        top: inherit;
        bottom: 15px;
    }
}
.mesh-slider{
    .slick-slider{
        border-left: 2px solid #60DFFF;
        border-right:  2px solid #60DFFF;
    }
    .slick-prev,
    .slick-next{
        width: 60px;
        height: 56px;
        z-index: 20;
        img{
            margin: auto;
        }
    }
    .slick-prev{
        left: -60px;
    }
    .slick-next{
        right: -60px;
    }
    .slick-slide{
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 18px;
        &.slick-center{
            font-size: 24px;
            width: 420px;
            background-color: #60DFFF;
            color: #070625;
        }
    }
    
}

.discipline-slider {
    .slick-list
    .slick-track {
        top: 25px;
    }
    .slick-prev,
    .slick-next{
        width: 16px;
        height: 34px;
        z-index: 20;
    }
    .slick-prev{
        left: 22px;
    }
    .slick-next{
        right: 22px;
    }
    .slick-dots {
        top: 0px;
        bottom: initial;
        li button::before{
            color:white;
            font-size: 10px;
        }
        li.slick-active {
            button:before {
                color: white
            }
        }
    }
    .slick-slide > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.profile-slider {
    .slick-list
    .slick-track {
        top: 0px;
        height: 100% !important;
    }

    .slick-list, .slick-slider {
        height: 100% !important;
        width: 100dvw !important;
    }

    .slick-prev,
    .slick-next{
        width: 16px;
        height: 34px;
        z-index: 20;
    }
    .slick-prev{
        left: 16%;
    }
    .slick-next{
        right: 16%;
    }
    .slick-dots {
        top: 0px;
        bottom: initial;
        li button::before{
            color:white;
            font-size: 10px;
        }
        li.slick-active {
            button:before {
                color: white
            }
        }
    }
    // .slick-slide > div {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
}

.course-slider{
    .slick-list
    .slick-track {
        top: 25px;
    }
    .slick-prev,
    .slick-next{
        width: 40px;
        height: 45px;
        z-index: 20;
    }
    .slick-prev{
        left: -50px;
    }
    .slick-next{
        right: -50px;
    }
    .slick-dots {
        top: 0px;
        bottom: initial;
        li button::before{
            color:white;
            font-size: 10px;
        }
        li.slick-active {
            button:before {
                color: white
            }
        }
    }
    .slick-slide{
        .slider-card{
            opacity: 0.5;
            margin-top: 40px;
            transition: ease all 0.7s;
        }
        .slider-control{
            visibility: hidden;
            opacity: 0;
            transition: ease all 0.7s;
            transition-delay: 0s;
        }
        &.slick-center{
            .slider-control{
                visibility: visible;
                opacity: 1;
                transition-delay: 0.5s;
            }
            .slider-card{
                margin-top: 0;
                opacity: 1;
            }
        }
    }    
}

.slick-center-slider {
    .slick-slide {
        transition: all ease-in-out 500ms;
        opacity: 0;
        scale: .9;
        .slick-item-icon {
            position: absolute;
            top: 12px;
            width: 20px;
            height: 20px;
            right: 0;
            left: 0;
            margin: auto;
        }
        .slick-selector {
            display: none;
        }
    }

    .slick-center.slick-current {
        transition: all ease-in-out 500ms;
        opacity: 1;
        scale: 1;
        position: relative;
        z-index: 60;
        .slick-item-icon {
            position: absolute;
            top: 12px;
            width: 20px;
            height: 20px;
            right: 0;
            left: 0;
            margin: auto;
        }
        .slick-selector {
            display: initial;
            &.flex {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .slick-center-slider {
        .slick-slide {
            .slick-item-icon {
                top: 19px;
            }
        }
    
        .slick-center.slick-current {
            .slick-item-icon {
                top: 19px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .slick-center.slick-current {
        .slick-selector {
            display: grid !important;
        }
    }

    .profile-slider {
        .slick-track {
            width: calc(100dvw * 4) !important;
        }
    }
}

// .slick-center.slick-current {
//     transform: scale(1.1);
// }