.card-career:hover {
    & .favorite-career-option {
        opacity: 1;
    }
}

@media screen and (max-width: 480px) {
    .quiz-screen-background {
        & .button-return {
            left: auto !important;
            right: calc((100dvw * .9) - 20px) !important;
            top: calc((100dvw * .2) - 5px) !important;
        }
    }

    .auth-page {
        & .button-return {
            top: 20px !important;
            left: 20px !important;
        }
    }
}