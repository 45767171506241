@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-custom-scroll/dist/customScroll.css";

@import './typography';
@import './layout';
@import './animation';
@import './form';
@import './scroll';
@import './intro';
@import './career';
@import './slider';
@import './quiz';
@import './buttons';