@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #60DFFF;
    }

    28% {
        transform: translateY(-7px);
        background-color: #60DFFF;
    }

    44% {
        transform: translateY(0px);
        background-color: #60DFFF;
    }
}

@keyframes OpacityAnimation {
    0% {
        opacity: 0;
    }

    28% {
        opacity: 0.3;
    }

    44% {
        opacity: 0.6;
    }

    70% {
        opacity: 1;
    }
}

@keyframes OpacityHalfAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .5;
    }
}

@keyframes OpacityChatAnimation {
    0% {
        opacity: 0;
    }

    48% {
        opacity: 0.3;
    }

    64% {
        opacity: 0.6;
    }

    90% {
        opacity: 1;
    }
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.image1-animation-float {
    animation: floatAnimation1 4s ease-in-out infinite; /* Ajusta la duración según tus necesidades */
}

.image2-animation-float {
    animation: floatAnimation2 5s ease-in-out infinite; /* Ajusta la duración según tus necesidades */
}

.image3-animation-float {
    animation: floatAnimation3 3s ease-in-out infinite; /* Ajusta la duración según tus necesidades */
}

.image4-animation-float {
    animation: floatAnimation4 6s ease-in-out infinite; /* Ajusta la duración según tus necesidades */
}

@keyframes floatAnimation1 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes floatAnimation2 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes floatAnimation3 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes floatAnimation4 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.popup-container:before {
    content: '';
    background-image: url('../images/left-cloud.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 35%;
    aspect-ratio: 2;
    animation: leftCloudIn 0.7s ease-out forwards;
}

.popup-container:after {
    content: '';
    background-image: url('../images/right-cloud.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    width: 35%;
    aspect-ratio: 2;
    animation: rightCloudIn 0.7s ease-out forwards;
}

.popup {
    animation: popupAnimation 0.7s ease-in;
}

.popup-content {
    opacity: 0;
    animation: fadeInText 0.4s ease-out forwards;
    animation-delay: 0.3s;
}

@keyframes popupAnimation {
    0% {
      opacity: 0.2;
      transform: scale(0.6);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}

@keyframes fadeInText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes rightCloudIn {
    from {
      bottom: -35%;
      right: -35%
    }
    to {
        bottom: 0;
        right: 0;
    }
}

@keyframes leftCloudIn {
    from {
      bottom: -35%;
      left: -35%
    }
    to {
        bottom: 0;
        left: 0;
    }
}

.home-loop, .home-loop-before::before {
  animation: fadeHomeIn 2s ease-out, 3s infinite ease-in glowLoop;
  animation-delay: 0s, 1s;
}

.fadeUp {
    animation: fadeInUp 1.5s ease-out forwards
}

@keyframes fadeInUp {
    0% {
      opacity: .5;
      transform: translateY(30dvh);
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  }

@keyframes fadeHomeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes glowLoop {
    0% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.7;
    }
  }