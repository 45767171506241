::-webkit-scrollbar {
  background-color: #070625;
    border-radius: 16px;
    width: 12px;
}

::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: 1px solid white;
    min-height: 90px;
}

.tmc-scroll {
    .rcs-custom-scrollbar {
        width: 45px;
    }
    .rcs-outer-container {
        height: 100%;
        position: relative;
        &:after{
            content: '';
            height: 100%;
            width: 12px;
            background: #070625;
            position: absolute;
            top: 0;
            right: 17px;
            border-radius: 16px;
        }
    }
    .rcs-inner-container{
        height: 100%;
    }
    .rcs-inner-handle {
        position: relative;
        border: 1px solid white;
        background: #070625;
        border-radius: 16px;
        top: -15px;
        width: 12px;
        height: 60px;
    }
}


.simple-scroll {
    scroll-padding: 20px;
    scroll-margin: 20px;
    margin-right: 30px;
    margin-left: 10px;
}

.simple-scroll::-webkit-scrollbar {
    display: initial;
    width: 10px;
}

.simple-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
    background-clip: padding-box;
}

.simple-scroll::-webkit-scrollbar-track {
    width: 35px;
    border-radius: 5px;
}

@media screen and (max-width: 480px) {
    ::-webkit-scrollbar, .simple-scroll::-webkit-scrollbar {
          width: 6px;
    }
}