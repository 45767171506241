body {
    background-color: #070625;
    font-family: "Monda";
    color: #60DFFF;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
}

.bg-main {
    background-image: url('../images/bg-main.jpg');
    background-size: cover;
    background-position: center;
}

.bg-main-alt {
    background-image: url('../images/bg-main-2.jpg');
    background-size: cover;
    background-position: center;
    &.bg-hearth{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            background-image: url('../images/bg-hearth.svg');
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            margin: auto;
            top: 140px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
        }
    }
}

.bg-intro:before {
    content: '';
    background-image: url('../images/bg-intro.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute; 
    top: 0;
    bottom: 0; 
    left: 3.75%; 
    right: 0; 
    margin: auto;
    height: 100%;
    aspect-ratio: 1.3;
}

.bg-popup {
    background-image: url('../images/bg-popup.svg');
    background-size: cover;
    background-position: center;
    &:before{
        content: '';
        background-image: url('../images/icon-logo.svg');
        position: absolute; 
        top: 55px; 
        left: 0; 
        right: 0; 
        margin: auto;
        width: 64px;
        height: 64px;
    }
}

.bg-auth {
    background-image: url('../images/bg-auth.jpg');
    background-size: cover;
    background-position: center;
}

.bg-login {
    background-image: url(../../../public/images/bg-login.svg);
    background-size: cover;
    background-position: center;
    &:before{
        content: '';
        background-image: url('../images/icon-logo.svg');
        position: absolute; 
        top: 20px; 
        left: 0; 
        right: 0; 
        margin: auto;
        width: 64px;
        height: 64px;
    }
}

.bg-loading-form {
    & form {
        animation: OpacityAnimation .9s ease-in-out;
    }

    & .bg-login {
        animation: OpacityAnimation .5s ease-in-out;
    }
}

.bg-loading {
    background-image: url('../images/bg-main.jpg');
    background-size: cover;
    background-position: center;
    & .loading-content {        
        animation: OpacityAnimation .9s ease-in-out;
    }

    &:before {
        content: '';
        background-image: url('../images/loading-circle.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-size: cover;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        z-index: 2;
        
        animation: OpacityAnimation .7s ease-in-out, rotate 10s infinite linear;
    }
    &:after {
        content: '';
        background-image: url('../images/loading-circle-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-size: cover;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        z-index: 1;
        animation: OpacityAnimation .65s ease-in-out;
    }
    .bg-mountain {
        animation: OpacityAnimation .5s ease-in-out;
        background-image: url('../images/loading-mountain.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-size: cover;
        position: absolute;
        width: 295px;
        height: 264px;
        bottom: -40px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 3;
    }
}

.bg-loading-assets {
    background-image: url('../images/bg-main.jpg');
    background-size: cover;
    background-position: center;
    &:before {
        content: '';
        background-image: url('../images/loading-circle.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-size: contain;
        position: absolute;
        animation: rotate 10s infinite linear;
        inset: 0;
        width: 80%;
        height: 80%;
        margin: auto;
        z-index: 2;
    }
}

.bg-dashboard {
    background-image: url('../images/backgrounds/bg-main-loading.svg');
    background-size: cover;
    background-position: center;
}

.bg-loading-decision {
    animation: OpacityAnimation 1s ease-in-out forwards;
}

.bg-feature:before {
    content: '';
    background-image: url('../images/bg-features.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    left: 7.2%;
    z-index: 50;
    width: 85.5%;
    aspect-ratio: 2.5;
}

.bg-loading-page {
    & .bg-feature:before, & .bg-feature-small:before {
        animation: OpacityAnimation .5s ease-in-out;
    }
}

.bg-feature-big-simple:before {
    content: '';
    background-image: url('../images/bg-feature-big-simple.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 50;
    height: 80dvh;
    aspect-ratio: 1;
    margin: auto
}

.bg-feature-big {
    &:before {
        content: '';
        background-image: url('../images/bg-feature-big.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-origin: content-box;
        background-clip: content-box;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: .5;
        z-index: 70;
        height: 100dvh;
        aspect-ratio: 1.61;
    }

    &.feature-loading::before {
        animation: OpacityHalfAnimation .8s ease-in-out;
    }
}

.bg-feature-big:after {
    content: '';
    background-image: url('../images/compass.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    left: 44.5%;
    z-index: 40;
    width: 11%;
    aspect-ratio: 2;
}

.bg-feature-small-only:before {
    content: '';
    background-image: url('../images/bg-features-small.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    left: 4.6%;
    z-index: 50;
    width: 90%;
    aspect-ratio: 2.5;
}

.bg-feature-small:before {
    content: '';
    background-image: url('../images/bg-features-small.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    left: 4.6%;
    z-index: 50;
    width: 90%;
    aspect-ratio: 2.5;
}

.bg-oldwoman{
    &:before {
        content: '';
        // background-image: url('../images/old-woman.png');    
        background-image: url('../images/old-woman-new.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-origin: content-box;
        background-clip: content-box;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 50;
        right: 0;
        // height: 75%;
        height: 72%;
        margin: auto;
        // aspect-ratio: 1.4;
        aspect-ratio: 1.24;
    }
    &.loading-conversation::before {
        animation: OpacityAnimation ease-in-out 0.65s forwards
    }
}

.bg-compass:after, .bg-feature:after, .bg-feature-small:after {
    content: '';
    background-image: url('../images/compass.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    right: 44.4%;
    z-index: 80;
    width: 11.5%;
    aspect-ratio: 2;
}
.bg-compass.loading-conversation:after {
    animation: OpacityAnimation ease-in-out 0.4s forwards
}

.bg-menu-dashboard:after, .bg-menu-dashboard-disabled:after {
    content: '';
    background-image: url('../images/compass_simple.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 80;
    width: 100%;
    aspect-ratio: 2;
}

.bg-menu-dashboard.light:after {
    content: '';
    background-image: url('../images/compass-light.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 80;
    width: 100%;
    aspect-ratio: 2;
}

.bg-menu-dashboard:hover:after,
.dashboard-menu-tutorial.bg-menu-dashboard:after {
    content: '';
    background-image: url('../images/bg-menu-dashboard-big.svg');    
    animation: OpacityChatAnimation 800ms ease-in;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 80;
    width: 100%;
    aspect-ratio: 2;
}

.cloud-page:before {
    content: '';
    background-image: url('../images/left-cloud.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 60;
    width: 35%;
    aspect-ratio: 2;
}

.cloud-page:after {
    content: '';
    background-image: url('../images/right-cloud.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 40;
    width: 35%;
    aspect-ratio: 2;
}

.loading-conversation .cloud-page:after {
    animation: rightCloudIn 0.5s ease-out forwards;
}

.loading-conversation .cloud-page:before {
    animation: leftCloudIn 0.5s ease-out forwards;
}

.loading-conversation, .initial-input-page, .bg-loading-page {

    & .bubble-conversation {
        animation: OpacityAnimation ease-in-out 0.8s forwards;

        & .bubble-conversation-content {
            animation: OpacityAnimation ease-in-out 0.95s forwards;
        }
    }
}


.gradient-purple {
    background: linear-gradient(0deg, rgba(97.96, 10.47, 209.31, 0.55) 0%, rgba(60.83, 42.40, 166.81, 0) 70%), linear-gradient(0deg, rgba(97.96, 10.47, 209.31, 0.45) 0%, rgba(60.83, 42.40, 166.81, 0) 0%);
    position: absolute;
    z-index: 60;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    aspect-ratio: 2.2;
}

.only-gradient-purple-reverse {
    background: linear-gradient(180deg, rgba(164,26, 232, .2) 0%, rgba(255, 255, 255, 0) 100%)
}

.user-demo::before {
    content: '';
    // background-color: #000000d5;
    position: fixed;
    inset: 0;
    z-index: 250;
    // transition: background-color 10s ease-out;
    animation: tutorial-demo 3s forwards;
}

.user-demo .input-demo {
    z-index: 270;
    animation: tutorial-input-demo 3s forwards;
}

.constelation-loading {
    & .bg-compass::after {
        animation: fadeHomeIn .4s ease-in-out forwards;
    }
    & .bg-result-loading {
        &::before {
            animation: fadeHomeIn .4s ease-in-out forwards;
        }
    }

    & .title-constelation {
        animation: fadeHomeIn .55s ease-in-out forwards;
    }

    & .content-constelation {
        animation: fadeHomeIn .75s ease-in-out forwards;
    }

    & .description-constelation {
        animation: fadeHomeIn .9s ease-in-out forwards;
    }
}

@-webkit-keyframes tutorial-demo {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #00000029;
    }
    100% {
        background-color: #000000e1;
    }
}

@keyframes tutorial-demo {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #00000029;
    }
    100% {
        background-color: #000000e1;
    }
}

@-webkit-keyframes tutorial-input-demo {
    0% {
        opacity: 1;
        box-shadow: none;
    }
    45% {
        opacity: .6;
        box-shadow: 0px 0px 32px 5px #ffffff0b;
    }
    60% {
        opacity: .6;
        box-shadow: 0px 0px 32px 5px #ffffff49;
    }
    100% {
        opacity: 1;
        box-shadow: 0px 0px 32px 5px #ffffffb7;
    }
}

@keyframes tutorial-input-demo {
    0% {
        opacity: 1;
        box-shadow: none;
    }
    45% {
        opacity: .6;
        box-shadow: 0px 0px 32px 5px #ffffff0b;
    }
    60% {
        opacity: .6;
        box-shadow: 0px 0px 32px 5px #ffffff49;
    }
    100% {
        opacity: 1;
        box-shadow: 0px 0px 32px 5px #ffffffb7;
    }
}

.user-demo-out::before {
    animation: tutorial-demo-reverse 5s forwards;
}

.user-demo-out .input-demo {
    z-index: 270;
    animation: tutorial-input-demo-reverse 5s forwards;
}

@-webkit-keyframes tutorial-demo-reverse {
    100% {
        background-color: transparent;
    }
    50% {
        background-color: #00000029;
    }
    0% {
        background-color: #000000e1;
    }
}

@keyframes tutorial-demo-reverse {
    100% {
        background-color: transparent;
    }
    50% {
        background-color: #00000029;
    }
    0% {
        background-color: #000000e1;
    }
}

@-webkit-keyframes tutorial-input-demo-reverse {
    100% {
        box-shadow: none;
    }
    60% {
        box-shadow: 0px 0px 32px 5px #ffffff0b;
    }
    45% {
        box-shadow: 0px 0px 32px 5px #ffffff49;
    }
    0% {
        box-shadow: 0px 0px 32px 5px #ffffffb7;
    }
}

@keyframes tutorial-input-demo-reverse {
    100% {
        box-shadow: none;
    }
    60% {
        box-shadow: 0px 0px 32px 5px #ffffff0b;
    }
    45% {
        box-shadow: 0px 0px 32px 5px #ffffff49;
    }
    0% {
        box-shadow: 0px 0px 32px 5px #ffffffb7;
    }
}

.circle:after {
    content: '';
    border: 2px solid #ffffff;
    background-color: #0D0416;
    width: 40px;
    height: 40px;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 999px;
    position: absolute;
}

@media (min-width: 1280px) {
    .circle:after{
        width: 55px;
        height: 55px;
    }
}

@media (min-width: 1536px) {
    .circle:after{
        width: 48px;
        height: 48px;
    }
}

.big-arrow:after {
    content: '';
    background-image: url('../images/big-arrow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    right: 0;
    z-index: 40;
    width: 85%;
    aspect-ratio: 1.7;

}

.line-initial-input:after {
    content: '';
    background-image: url('../images/lines/line-select-careers.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    height: 18px;
    width: 90%;
    z-index: 10;
    top: -8px;
}

.line-initial-input:before {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, rgba(19, 12, 43, 0.7) 50%, rgba(19, 12, 43, 1) 100%);   
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    width: 100%;
    pointer-events: none;
    z-index: 10;
}

.careers-step:before {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, rgba(62, 22, 118, 1) 100%);   
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.5rem;
    width: 100%;
    pointer-events: none;
    z-index: 10;
}

.arrow-big-left:before {
    content: '';
    background-image: url('../images/arrows/arrow-big-left.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    left: 332px;
    top: 92.5px;
    width: calc(15.5dvw + 25px);
    z-index: 100;
    aspect-ratio: 15;
}

.arrow-big-right:after {
    content: '';
    background-image: url('../images/arrows/arrow-big-right.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-clip: content-box;
    position: absolute;
    right: 332px;
    top: 92.5px;
    width: calc(15.5dvw + 25px);
    z-index: 100;
    aspect-ratio: 17;
}

:focus-visible {
    outline: none;
}

:focus:not(:focus-visible) {
    outline: none;
}

.ff-cp--regular {
    font-family: "Chakra Petch";
    font-weight: normal;
}

.ff-cp--light {
    font-family: "Chakra Petch";
    font-weight: 200;
}

.ff-cp--semibold {
    font-family: "Chakra Petch";
    font-weight: 500;
}

.ff-cp--bold {
    font-family: "Chakra Petch";
    font-weight: bold;
}

.ff-m--regular {
    font-family: "Monda";
    font-weight: normal;
}


.ff-m--bold {
    font-family: "Monda";
    font-weight: bold;
}

.text--webkit {
    text-align: -webkit-center;
}

.grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
}

.row-span-9 {
    grid-row: span 9 / span 9;
}

.dashboard-menu {
    transition: width ease-in-out 400ms, height 400ms ease-in-out;

    .item-menu {
        display: none;
        opacity: 0;
        animation-name: opacity-menu;
        animation-duration: 1.8s;

        .tooltip, .tooltip-inactive {
            transition: opacity ease-in-out 300ms;
            opacity: 0;
        }

        .card {
            opacity: 1;
            transition: opacity ease-in-out 250ms;
        }
        
        img {
            opacity: .4;
            transition: opacity ease-in-out 250ms;
        }

        .tooltip-active {
            opacity: 1;
        }

        &:hover {
            .item-menu-body  {
                border: .5px #ffffff8f solid;
            }

            .tooltip {
                opacity: 1;
            }

            .card {
                opacity: 0;
            }

            img {
                opacity: 1;
            }
        }
    }

    &:hover, &.dashboard-menu-tutorial {
        .item-menu {
            display: flex;
            opacity: 1;
        }
    }
}

@keyframes scaleInDown {
    0% {
        scale: 1.10;
    }
    100% {
        scale: 1;
    }
}

.border-gradient {
    //border-image: linear-gradient(180deg, #fff -1.77%, rgba(164, 26, 232, 0.20) 100%);
    //border-image-slice: 1;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

@keyframes opacity-menu {
  0%   {opacity: 0; }
  25%  {opacity: .15; }
  50%  {opacity: .60; }
  100% {opacity: 1; }
}

.border-gradient {
    border-image: linear-gradient(180deg, #fff 1%, rgba(164, 26, 232, .2) 100%);
    border-image-slice: 1;
}

.dashboard-gradient {
    background: linear-gradient(180deg, rgba(164, 26, 232, 0.14) -22%, rgba(164, 26, 232, 0.00) 1240%);
}

.joyride-element-show{
    position: relative;
    z-index: 200;
    opacity: 1 !important;
}

.joyride-element-show-2{
    z-index: 200;
}

.terms-conditions-popup {
    background: url(../../assets/images/modals/bg-terms.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.fadeIn-page {
    animation: OpacityAnimation .9s ease-in-out forwards
}

@media screen and (min-width: 1200px) {
    .bg-login {
        &:before{
            top: 55px; 
        }
    }
}

@media screen and (max-width: 480px) {

    .bg-loading-assets {
        background-image: url('../images/bg-main.jpg');
        background-size: cover;
        background-position: center;
        &:before {
            content: '';
            background-image: url('../images/loading-circle.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-size: contain;
            position: absolute;
            animation: rotate 10s infinite linear;
            inset: 0;
            width: 100%;
            height: 100%;
            margin: auto;
            z-index: 2;
        }
    }
    

    .bg-intro {
        left: 0; 
        height: 40%;
    }

    .bg-intro:before {
        background-image: url('../images/bg-intro-mobile.svg');
        left: 0;
        height: calc(100dvw * .8);
        background-size: contain;
        max-width: 100%;
    }

    .bg-login {        
        background-image: url(../../../public/images/bg-login-mobile.svg);
    }


    .bg-login::before {
        top: 21% !important;
        width: 45px;
        height: 45px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .bg-loading {
        &:before {
            height: auto;
            aspect-ratio: .78;
        }
        &:after {
            height: auto;
            aspect-ratio: .89;
        }
    }

    .bg-oldwoman:before {
        max-width: 100%;
        // height: 50%;
        height: 60%;
        bottom: -20px;
    }

    .bg-oldwoman-tutorial:before {
        content: '';
        background-image: url('../images/old-woman-new.svg');
        // background-image: url('../images/old-woman.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-origin: content-box;
        background-clip: content-box;
        position: absolute;
        left: 0;
        z-index: 50;
        right: 0;
        margin: auto;
        aspect-ratio: 1.24;
        max-width: 100%;
        // height: 50%;
        height: 50%;
        bottom: -20px;
        opacity: 100;
        transition: opacity ease-in 4s;
    }

    .cloud-page:before, .cloud-page:after {
        display: none;
    }
    
    .bg-compass:after, .bg-feature:after, .bg-feature-small:after {
        content: '';
        right: 35.4%;
        z-index: 80;
        width: 30%;
        aspect-ratio: 2;
    }

    .bg-feature-small:before, .bg-feature:before {
        left: 0;
        right: 0;
        width: 100%;
        height: 70%;
    }

    .line-initial-input:after {
        width: 80%;
        left: 10px;
        right: 10px;
    }

    .terms-conditions-popup {
        background: url(../../assets/images/modals/bg-terms-mobile.svg);
        background-repeat: no-repeat;
        background-size: contain;
        max-width: 100%;
        padding: 7px 20px 20px;
        margin-bottom: 20px;
    }

    .terms-conditions-popup > div {
        border-radius: 14px;
    }

    .terms-conditions-popup > div > div div:nth-child(1) {
        border-color: transparent;
        border-bottom-color: rgba(255, 255, 255, .5);
    }

    .user-option {
        font-family: "Monda";
        font-weight: normal;
    }

    .bg-feature-big:before {
        aspect-ratio: .6;
        max-width: 100%;
        height: auto;
        left: 0;
    }

    .bg-feature-big:after {
        left: 32.5%;
        z-index: 40;
        width: 36%;
        aspect-ratio: 2;
    }

    .bg-menu-dashboard:hover:after,
    .dashboard-menu-tutorial.bg-menu-dashboard:after {
        content: '';
        background-image: none;
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
        z-index: 0;
        width: 0px;
        height: 0px;
        aspect-ratio: auto;
        position: fixed;
        display: none;
    }

    .bg-main-alt {
        &.bg-hearth{
            position: relative;
            &:after{
                width: 100%;
                background-size: contain;
                height: 100%;
                margin: 0;
                background-repeat: no-repeat;
                background-position: center;
                top: 0px;
            }
        }
    }

    @keyframes OpacityAnimation {
        100% {
            opacity: 0;
        }
        
        70% {
            opacity: .25;
        }
        
        30% {
            opacity: .6;
        }

        0% {
            opacity: 1;
        }
    }
}